import {
  ChangeDetectorRef,
  Component,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { CmsComponentData } from '@spartacus/storefront';
import { BulkOrderService } from '../../services/bulk-order-service';
import { Observable } from 'rxjs';
import * as XLSX from 'xlsx';
import { StorageService } from '../../../../SGRE-shared/services/storage.service';
import { HttpClient } from '@angular/common/http';
@Component({
  selector: 'app-upload-file',
  templateUrl: './upload-file.component.html',
  styleUrl: './upload-file.component.scss',
})
export class UploadFileComponent {
  array: any = [];
  nextPage$: Observable<number>;
  currentpage$: Observable<number>;
  isSelected: string;
  public userRoles: any[];
  role: string;
  public componentData: any;
  type = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
  invalidFile = '';
  isError: boolean = false;
  isErrorFile: boolean = false;
  quantityRegex: RegExp = /^\d+$/;
  isQtyError: boolean = false;
  file: File | null = null;


  constructor(
    public component: CmsComponentData<any>,
    public bulkservice: BulkOrderService,
    public storageService: StorageService,
    private cdRef: ChangeDetectorRef,
    private http: HttpClient

  ) { }

  @ViewChild('fileUploader') fileUploader: ElementRef;

  ngOnInit(): void {
    this.currentpage$ = this.bulkservice.isNext$;
    this.getUserRole();
  }

  getUserRole() {
    let userRole: any = '';
    userRole = this.storageService.userRoles;
    if (userRole?.includes('Requester') && !userRole?.includes('Purchaser')) {
      this.role = 'Requester';
    } else if (
      userRole?.includes('Purchaser') &&
      !userRole?.includes('Requester')
    ) {
      this.role = 'Purchaser';
    } else if (
      userRole?.includes('Purchaser') &&
      userRole?.includes('Requester')
    ) {
      this.role = 'Purchaser&Requester';
    }
  }

  remove(i: any) {
    this.array.splice(i, 1);
    this.fileUploader.nativeElement.value = null;
    this.bulkservice.setUploadFileArrayLength(this.array.length);
  }

  uploadImage(event: any) {
    if (this.array.length > 0)
      this.array.splice(0, 1);
    const file: any = event.currentTarget.files;
    if (file[0].type === this.type) {
      this.array.push(event?.currentTarget?.files[0]);
      this.bulkservice.setUploadFileArrayLength(this.array.length);
      this.invalidFile = '';
      this.readExcelFile(event?.currentTarget?.files[0]);
    } else {
      this.invalidFile = file[0].name;
      this.isError = false;
      this.isErrorFile = false;
      this.isQtyError = false;
    }
    this.bulkservice.setUploadFileName(this.invalidFile);
  }

  readExcelFile(file: File) {
    this.isError = false;
    this.isErrorFile = false;
    const productsCodeList = [];
    let tableData = [];
    const arrayData = [];
    let headers = ['partNumber', 'quantity'];
    const reader = new FileReader();
    reader.onload = (event) => {
      const data = new Uint8Array(
        (event.target as FileReader).result as ArrayBuffer
      );
      const workbook = XLSX.read(data, { type: 'array' });

      const worksheet = workbook.Sheets[workbook.SheetNames?.at(0)];
      const jsonData: any[] = XLSX.utils.sheet_to_json(worksheet, {
        header: 1,
      });

      for (let row in jsonData) {
        if (+row > 0) {
          let obj = {};
          for (let rowElement in jsonData[row]) {
            const key = headers[rowElement];
            const value = '' + jsonData[row][rowElement];
            obj[key] = value;
          }
          arrayData.push(obj);
        }
      }
      let uploadedArray = arrayData.length > 24 ? (arrayData.slice(0, 25)) : arrayData;
      tableData = Array.from(
        uploadedArray.reduce((map, item) => map.set(item.partNumber, item), new Map()).values()
      );
      tableData.forEach((item) => {
        item.partNumber = item.partNumber.trim();
        if (item.partNumber != undefined) {
          productsCodeList.push(item.partNumber);
        }
      });
      this.bulkservice.excelDataSubject.next(tableData);
      this.bulkservice.productNameList.next(productsCodeList);
      if (
        (jsonData?.length == 1 &&
          jsonData?.at(0)?.at(0).includes('Part Number') &&
          jsonData?.at(0)?.at(1).includes('Quantity')) ||
        jsonData?.at(0).length < 2 ||
        jsonData?.at(0).length > 2
      ) {
        this.isError = true;
        this.isErrorFile = false;
        this.isQtyError = false;
        this.remove(0);
        this.cdRef.markForCheck();
        this.bulkservice.setUploadFileName(file.name);
      } else {
        this.isQtyError = false;
        this.isErrorFile = false;
        this.isError = false;
        this.cdRef.markForCheck();
      }
      tableData.forEach((item) => {
        if (
          !item.quantity ||
          !item.partNumber ||
          (jsonData?.at(0)?.at(0) != 'Part Number' &&
            jsonData?.at(0)?.at(1) != 'Quantity')
        ) {
          this.isErrorFile = true;
          this.isError = false;
          this.isQtyError = false;
          this.remove(0);
          this.cdRef.markForCheck();
          this.bulkservice.setUploadFileName(file.name);
        } else if (this.quantityRegex?.test(item.quantity) == false) {
          this.isQtyError = true;
          this.isErrorFile = false;
          this.isError = false;
          this.remove(0);
          this.cdRef.markForCheck();
          this.bulkservice.setUploadFileName(file.name);
        }
      });
    };
    reader.readAsArrayBuffer(file);
  }

  onDragOver(event: DragEvent) {
    event.preventDefault();
  }

  onDrop(event: DragEvent) {
    if (this.array.length > 0)
      this.array.splice(0, 1);
    event.preventDefault();
    if (event.dataTransfer?.files) {
      this.file = event.dataTransfer.files[0];
      if (this.file.type === this.type) {
        this.array.push(this.file);
        this.bulkservice.setUploadFileArrayLength(this.array.length);
        this.invalidFile = '';
        this.readExcelFile(this.file);
      } else {
        this.invalidFile = this.file.name;
        this.isError = false;
        this.isErrorFile = false;
        this.isQtyError = false;
      }
      this.bulkservice.setUploadFileName(this.invalidFile);
    }
  }

}
